import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import * as sections from '../components/sections';

interface HomepageProps {
  data: {
    homepage: {
      id: string;
      title: string;
      description: string;
      image: { id: string; url: string };
      blocks: sections.HomepageBlock[];
    };
  };
}

export default function Homepage(props: HomepageProps): JSX.Element {
  const { homepage } = props.data;
  return (
    <Layout {...homepage}>
      {homepage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block;
        const Component = sections[blocktype] || null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <Component key={id} {...(componentProps as any)} />;
      })}
    </Layout>
  );
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HeroContent
        ...SliderContent
        ...FeatureListContent
        ...CtaContent
        ...LogoListContent
        ...TestimonialListContent
        ...BenefitListContent
        ...StatListContent
        ...ProductListContent
        ...FormContent
      }
    }
  }
`;
